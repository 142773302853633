<template>
  <div class="list inner">
    <Header :now="10" />
    <div class="content">
      <div class="clumb">
        <div class="btn-back" @click="onBack"><span class="ic"></span>返回</div>
        <div class="clum">
          您的位置：赛事首页-
          <div class="now">参赛详情页</div>
        </div>
      </div>
      <div class="part-des">武将背景介绍</div>
      <div class="swiper-background"></div>
      <div class="swiper-form-box">
        <swiper :options="swiperOption" class="swiper-form swiper-no-swiping" ref="swiperForm">
          <swiper-slide class="slide-box swiper-no-swiping" v-for="(item, index) in roleInfo" :key="index">
            <div class="name">{{ item.name }}</div>
            <div class="des">{{ item.des }}</div>
            <div class="supplement">{{ item.supplement }}</div>

            <div class="man">
              <img class="role-avatar" :src="require(`../assets/images/man_${index + 1}.png`)" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="arr-box">
        <div class="ic arr-left disabled" v-if="swiperIndex == 0"></div>
        <div
          class="ic arr-left"
          v-else
          :class="swiperIndex > 0 ? 'active' : ''"
          @click="showPop(0)"
        ></div>
        <div class="number">
          <span class="now">{{ swiperIndex + 1 }}</span
          >/ 7
        </div>
        <div class="ic arr-right disabled" v-if="swiperIndex == 6"></div>
        <div
          class="ic arr-right"
          v-else
          :class="swiperIndex >= 0 && swiperIndex < 6 ? 'active' : ''"
          @click="showPop(1)"
        ></div>
      </div> -->
      <div class="change-box">
        <div class="avatars">
          <div v-for="(item, index) in 4" class="item" @click="showPop(index)"
            :class="[index === swiperIndex ? 'active' : '']" :key="item">
            <img :src="require(`../assets/images/avatar_${index + 1}.png`)" alt="" />
          </div>
        </div>
        <div class="change-btn">
          <div v-for="(item, index) in 4" class="item" @click="handleActive(index)"
            :class="[index === swiperIndex ? 'active' : '']" :key="item"></div>
        </div>
      </div>
      <div :class="[swiperIndex < 3 ? 'part-tip' : 'part-tg']"></div>
      <div class="general-form" v-if="swiperIndex < 3">
        <div class="strength">
          <div>武 将 体 力：</div>
          <div class="flex">
            <img v-for="(star, index) in stars" v-bind:src="star.src" :key="index" @click="rating(index)" alt="星星图片"
              class="xue" /><span class="red">(请选择)</span>
          </div>
        </div>
        <div class="form-item middle">
          <div class="name">技 能 名 称：</div>
          <div class="cont">技 能 内 容：</div>
        </div>
        <div class="form-item">
          <div class="name">
            <input type="text" placeholder="可输入2字" class="input-skill" maxlength="2" v-model="key1" />
          </div>
          <div class="cont">
            <textarea placeholder="请输入你的想法哦..." class="input-cont" maxlength="150" v-model="val1"></textarea>
            <div class="com-num">{{ val1.length }}/150</div>
          </div>
        </div>

        <div v-if="skillNum >= 2">
          <div class="form-item middle">
            <div class="name">技 能 名 称：</div>
            <div class="cont">技 能 内 容：</div>
          </div>
          <div class="form-item">
            <div class="name">
              <input type="text" placeholder="可输入2字" class="input-skill" maxlength="2" v-model="key2" />
            </div>
            <div class="cont">
              <textarea placeholder="请输入你的想法哦..." class="input-cont" maxlength="150" v-model="val2"></textarea>
              <div class="com-num">{{ val2.length }}/150</div>
            </div>
          </div>
        </div>
        <div v-if="skillNum >= 3">
          <div class="form-item middle">
            <div class="name">技 能 名 称：</div>
            <div class="cont">技 能 内 容：</div>
          </div>
          <div class="form-item">
            <div class="name">
              <input type="text" placeholder="可输入2字" class="input-skill" maxlength="2" v-model="key3" />
            </div>
            <div class="cont">
              <textarea placeholder="请输入你的想法哦..." class="input-cont" maxlength="150" v-model="val3"></textarea>
              <div class="com-num">{{ val3.length }}/150</div>
            </div>
          </div>
        </div>
        <div class="form-item middle" v-if="skillNum < 3">
          <div class="name">新 增 技 能：</div>
          <div class="add" @click="onAddSkill">+</div>
        </div>

        <div class="form-item middle">
          <div class="name">设计师昵称：</div>
          <div>
            <input type="text" placeholder="最多可输入六字" class="input-design" maxlength="6" v-model="author" />
          </div>
        </div>
        <div class="think">
          <div class="name">
            设 计 思 路： <span class="xuantian">（选填）</span>
          </div>
          <div>
            <textarea placeholder="请输入你的想法哦..." class="area" maxlength="500" v-model="desc"></textarea>
            <div class="com-num">{{ desc.length }}/500</div>
          </div>
        </div>
        <div class="submit-box">
          <button type="button" class="ic-btn btn-o" :class="isSubmit ? 'disabled' : ''" @click="onSubmit"
            :disabled="isSubmit"></button>
        </div>
        <div class="attention">
          <p>注意事项：</p>
          <p>
            1、武将体力范围为2-8，技能数量范围为1-3，请注意描述准确性，避免歧义；
          </p>
          <p>
            2、技能名字为两字，单个技能描述字数在150字以内，可简单写明设计思路，帮助大家理解技能；
          </p>
          <p>
            3、参赛作品若未通过可进行修改（此处”修改“指重新投稿，并非直接进行二次编辑）,审核通过在互动排行展示后不可修改；
          </p>
          <p>
            4、严禁抄袭他人作品或投稿内容涉及暴力、反动、色情、与政治相关、他人隐私等非法内容；
          </p>
          <p>
            5、每个命题可进行最多2份正式创意投稿，但是参与奖励仅能领取一份哦。
          </p>
        </div>
      </div>
      <div v-else class="tips-form">
        <div class="form-item middle">
          <div class="cont">锦 囊 效 果：</div>
        </div>
        <div class="form-item">
          <div class="cont">
            <textarea placeholder="请输入你的想法哦..." class="input-cont" maxlength="150" v-model="val1"></textarea>
            <div class="com-num">{{ val1.length }}/150</div>
          </div>
        </div>
        <div class="form-item middle">
          <div class="name">设计师昵称：</div>
          <div>
            <input type="text" placeholder="最多可输入6字" class="input-design" maxlength="6" v-model="author" />
          </div>
        </div>
        <div class="think">
          <div class="name">
            设 计 思 路 :<span class="xuantian">（选填）</span>
          </div>
          <div>
            <textarea placeholder="请输入你的想法哦..." class="area" maxlength="500" v-model="desc"></textarea>
            <div class="com-num">{{ desc.length }}/500</div>
          </div>
        </div>
        <div class="submit-box">
          <button type="button" class="ic-btn btn-o" :class="isSubmit ? 'disabled' : ''" @click="onSubmit"
            :disabled="isSubmit"></button>
        </div>
        <div class="attention">
          <p>注意事项：</p>
          <p>
            1、武将体力范围为2-8，技能数量范围为1-3，请注意描述准确性，避免歧义；
          </p>
          <p>
            2、技能名字为两字，单个技能描述字数在150字以内，可简单写明设计思路，帮助大家理解技能；
          </p>
          <p>
            3、参赛作品若未通过可进行修改（此处”修改“指重新投稿，并非直接进行二次编辑）,审核通过在互动排行展示后不可修改;
          </p>
          <p>
            4、严禁抄袭他人作品或投稿内容涉及暴力、反动、色情、与政治相关、他人隐私等非法内容；
          </p>
          <p>
            5、每个命题可进行最多2份正式创意投稿，但是参与奖励仅能领取一份哦。
          </p>
        </div>
      </div>
    </div>
    <Copyright v-show="!isChannel()" />
    <Pop :visible="isShowPop" @hidePop="hidePop">
      <div class="pop pop-tips animate__animated animate__fadeIn">
        <div class="ic pop-top">武将命题切换</div>
        <div class="pop-cont">
          <h3 class="p-title">亲爱的玩家：</h3>
          <div class="cont-t">
            <p>
              所选投稿武将将切换，如未完成投稿当前填写内容会被清空，您确认要继续切换吗?
            </p>
            <div class="btn-xx">
              <div class="btn-cancle" @click="hidePop">取消</div>
              <div class="btn-ok" @click="submitChange">确定</div>
            </div>
          </div>
        </div>
      </div>
    </Pop>
    <Pop :visible="isShowPopTips" @hidePop="hidePop">
      <div class="pop pop-tips animate__animated animate__fadeIn">
        <div class="ic pop-top">温馨提示</div>
        <div class="pop-cont">
          <h3 class="p-title">亲爱的玩家：</h3>
          <div class="cont-t">
            <p>
              创玩节一将成名2024活动投稿于2024年6月27日09:00正式开始，欢迎大家准时参与！
            </p>
          </div>
        </div>
      </div>
    </Pop>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "../components/content/header.vue";
import Login from "../components/content/login.vue";
import Copyright from "@/components/content/Copyright";
import Pop from "../components/content/pop.vue";
import { judgeWeb, isChannel } from "@/common/util";
import { reqSave } from "@/api/http";
import starOffImg from "@/assets/images/star_off.png";
import starOnImg from "@/assets/images/star_on.png";
import { roleInfo } from "@/common/config";
import { config } from "@/common/const";
import { debounce } from "@/common/util";
let myVue = {};
export default {
  name: "Form",
  components: {
    Header,
    Login,
    Copyright,
    Pop,
  },
  computed: {
    // swiper() {
    //   return this.$refs.swiperForm.swiper;
    // },
  },
  data() {
    return {
      agent: "pc", //是手机访问还是电脑
      token: "",
      roleInfo, isChannel,
      swiperIndex: 0,
      skillNum: 1, //技能数量
      swiperOption: {
        parallax: true, //视差效果
        mousewheel: false,
        noSwiping: true, //鼠标不能拖动
        loop: false,
        effect: "cards",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          progressbarOpposite: true, //使进度条分页器与Swiper的direction参数相反
          bulletElement: "li", //设定分页器指示器（小点）的HTML标签。
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
          bulletClass: "ic arro",
          bulletActiveClass: "active",
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: function () {
            //切换结束时，告诉我现在是第几个slide
            myVue.swiperIndex = this.activeIndex;
          },
        },
      },
      stars: [
        {
          src: starOnImg,
          active: false,
        },
        {
          src: starOnImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
        {
          src: starOffImg,
          active: false,
        },
      ],
      blood: 2, //血量
      role_type: 1, //角色id1夏侯楙2孙礼3陈式4费曜5诸葛亮6
      key1: "",
      key2: "",
      key3: "",
      val1: "",
      val2: "",
      val3: "",
      author: "",
      desc: "",
      pass: false, //必填项是否都填
      isSubmit: false, //是否已提交
      wid: 0, //作品id（修改时）
      isShowPop: false,
      isChangeType: 1, //上下切换0上1下
      isShowPopTips: false, //开始投稿日期提示弹框
      timer: null, //定时器
      timer2: null,
      config,
    };
  },
  beforeCreate() {
    myVue = this;
  },
  computed: {
    //监听多个数据时先定义成计算属性
    sumitObj: function () {
      const { blood, key1, val1, author } = this;
      return { blood, key1, val1, author };
    },
  },
  watch: {
    sumitObj: {
      deep: true,
      handler: function (newV) {
        var arr = Object.values(newV);

        if (!arr.includes("") && arr[0] > 0) {
          this.pass = true;
        } else {
          this.pass = false;
        }
      },
    },
  },
  mounted() {
    //判断pc还是h5
    this.agent = 'pc';

    this.token = localStorage.getItem("token");
    if (!this.token) {
      this.$router.push({ name: "index" });
      return;
    }
    let { role = 1 } = this.$route.query;
    this.role_type = role;
    this.swiperIndex = parseInt(role) - 1;
    this.$refs.swiperForm.swiper.slideTo(this.swiperIndex);

    let updateInfo = localStorage.getItem("workdetail"); //修改时
    if (updateInfo) {
      this.onWorkDetail();
    }
  },
  methods: {
    //添加技能数量
    onAddSkill() {
      if (this.skillNum < 3) {
        this.skillNum++;
      } else {
        this.$toast.show("最多三个技能");
        return;
      }
    },
    handleActive(value) {
      this.$refs.swiperForm.swiper.slideTo(value);
      this.swiperIndex = value;

      this.role_type = this.swiperIndex < 3 ? value + 1 : 0;
      this.hidePop();
      this.setValNull();
    },
    onReduceSkill() {
      if (this.skillNum > 2) {
        this.skillNum--;
      } else {
        this.$toast.show("至少一个技能");
        return;
      }
    },
    showPop(type) {
      this.isShowPop = true;
      this.isChangeType = type;
    },
    hidePop() {
      this.isShowPop = false;
      this.isShowPopTips = false;
    },
    //确定切换
    submitChange() {
      this.handleActive(this.isChangeType);
      // if (this.isChangeType) {
      //   this.onNext();
      // } else {
      //   this.onPrevious();
      // }
    },
    //切换上一个角色
    onPrevious() {
      let { swiperIndex } = this;
      if (swiperIndex > 0) {
        swiperIndex--;
        this.role_type--;
        this.$refs.swiperForm.swiper.slideTo(swiperIndex);
      } else {
        this.$toast.show("已经第一个了");
      }
      this.hidePop();
      this.setValNull();
    },
    //切换下一个角色
    onNext() {
      let { swiperIndex } = this;
      if (swiperIndex < 7) {
        swiperIndex++;
        this.role_type++;
        this.$refs.swiperForm.swiper.slideTo(swiperIndex);
      } else {
        this.$toast.show("已经最后一个了");
      }
      this.hidePop();
      this.setValNull();
    },
    setValNull() {
      this.key1 = "";
      this.key2 = "";
      this.key3 = "";
      this.val1 = "";
      this.val2 = "";
      this.val3 = "";
      this.author = "";
      this.desc = "";
    },
    //体力
    rating: function (index) {
      var total = this.stars.length; //星星总数
      var idx = index;
      idx = index + 1; //这代表选的第idx颗星-也代表应该显示的星星数量

      //进入if说明页面为初始状态
      if (this.blood == 0) {
        this.blood = idx;
        for (var i = 0; i < idx; i++) {
          this.stars[i].src = starOnImg;
          this.stars[i].active = true;
        }
      } else {
        //如果再次点击当前选中的星级-仅取消掉当前星级，保留之前的。
        if (idx == this.blood) {
          console.log("==");
          for (var i = index; i < total; i++) {
            this.stars[i].src = starOffImg;
            this.stars[i].active = false;
          }
          console.log(this.stars);
        }
        //如果小于当前最高星级，则直接保留当前星级
        if (idx < this.blood) {
          for (var i = idx; i < this.blood; i++) {
            this.stars[i].src = starOffImg;
            this.stars[i].active = false;
          }
        }
        //如果大于当前星级，则直接选到该星级
        if (idx > this.blood) {
          for (var i = 0; i < idx; i++) {
            this.stars[i].src = starOnImg;
            this.stars[i].active = true;
          }
        }
        var count = 0; //计数器-统计当前有几颗星
        for (var i = 0; i < total; i++) {
          if (this.stars[i].active) {
            count++;
          }
        }
        this.blood = count;
      }
    },
    //返回选角色
    onBack() {
      if (this.wid) {
        this.$router.push({ name: "work" });
      } else {
        history.back();
      }
    },
    //提交

    onSubmit:debounce(function () {

        this.submitInfo();
      }, 500),

    async submitInfo() {

      const {
        wid,
        role_type,
        blood,
        key1,
        key2,
        key3,
        val1,
        val2,
        val3,
        author,
        desc,
        token,
      } = this;

      let regexp_1 = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
      let regval1 = regexp_1.test(val1);
      let regval2 = regexp_1.test(val2);
      let regval3 = regexp_1.test(val3);
      let regdesc = regexp_1.test(desc);
      if (blood == 0 && this.swiperIndex < 3) {
        this.$toast.show("请选择武将体力");
        return;
      }
      if (!key1 && this.swiperIndex < 3) {
        this.$toast.show("请至少填写一个技能名称");
        return;
      }
      if (!val1 && this.swiperIndex < 3) {
        this.$toast.show("请至少填写一个对应技能内容");
        return;
      }
      if (!author) {
        this.$toast.show("设计师昵称为必填");
        return;
      }
      if (this.skillNum > 1) {
        if (!key2 && this.swiperIndex < 3 && val2) {
          this.$toast.show("有必填项为空，请完善您的投稿内容");
          return;
        }
        if (!val2 && this.swiperIndex < 3 && key2) {
          this.$toast.show("有必填项为空，请完善您的投稿内容");
          return;
        }
        if (!key3 && this.swiperIndex < 3 && val3) {
          this.$toast.show("有必填项为空，请完善您的投稿内容");
          return;
        }
        if (!val3 && this.swiperIndex < 3 && key3) {
          this.$toast.show("有必填项为空，请完善您的投稿内容");
          return;
        }
      }
      // if (!regval1) {
      //   this.$toast.show(`技能内容1不合法，请不要包含表情、特殊字符等`);
      //   return;
      // }
      // if (val2 && !regval2) {
      //   this.$toast.show(`技能内容2不合法，请不要包含表情、特殊字符等`);
      //   return;
      // }
      // if (val3 && !regval3) {
      //   this.$toast.show(`技能内容3不合法，请不要包含表情、特殊字符等`);
      //   return;
      // }
      // if (desc && !regdesc) {
      //   this.$toast.show(`设计思路内容不合法，请不要包含表情、特殊字符等`);
      //   return;
      // }
      if (val1.length + val2.length + val3.length > 250) {
        this.$toast.show("三个技能总内容不能超过250个字");
        return;
      }
      this.$toast.show("提交中...");
      this.isSubmit = true;
      let subobj = {
        type: this.swiperIndex < 3 ? 1 : 4,
        role_type,
        blood: this.swiperIndex < 3 ? blood : 0,
        key1,
        key2,
        key3,
        val1,
        val2,
        val3,
        author,
        desc,
        token,
      };
      console.log(subobj);
      if (wid) {
        subobj.wid = wid;
      }
      await reqSave(subobj)
        .then((res) => {
          if (res.status === 0) {
            this.$toast.show("提交成功，耐心等待审核~");
            this.key1 = "";
            this.key2 = "";
            this.key2 = "";
            this.val1 = "";
            this.val2 = "";
            this.val3 = "";
            this.author = "";
            this.desc = "";
            this.pass = false;
            setTimeout(() => {
              this.isSubmit = false;
              if (wid) {
                localStorage.removeItem("workdetail");
                this.$router.push({ name: "work" });
              }
            }, 1000);
          } else if (res.status == -1) {
            this.token = "";
            this.isSubmit = false;
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.isSubmit = false;
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.isSubmit = false;
          this.$toast.show("出错了");
        });
    },


    //作品详情
    onWorkDetail() {
      let info = JSON.parse(localStorage.getItem("workdetail"));
      this.wid = info.wid;
      this.key1 = info.key1;
      this.key2 = info.key2;
      this.key3 = info.key3;
      this.val1 = info.val1;
      this.val2 = info.val2;
      this.val3 = info.val3;
      this.author = info.author;
      this.desc = info.desc;
      this.blood = info.blood;
      if (info.key2) {
        this.skillNum = 2;
      }
      if (info.key3) {
        this.skillNum = 3;
      }
      this.rating(info.blood);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: auto;
}

.pop-top {
  background-position: 0 0;
  border-right: 0.02rem solid #a38b5c;
  border-left: 0.02rem solid #a38b5c;
  background: url(../assets/images/popu-header.png) no-repeat;
  background-size: cover;
}

.pop-cont {
  background-color: #faf8f0;

  .p-title {
    color: #6e5a46;
  }

  .cont-t {
    font-family: SourceHanSansCN-Medium;
    font-size: 0.2rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.5rem;
    letter-spacing: 0px;

    p {
      padding: 0;
    }
  }

  .btn-cancle {
    background: #faf8f0 url(../assets/images/cancel.png) no-repeat;
    background-size: cover;
  }

  .btn-ok {
    background: #faf8f0 url(../assets/images/confirm.png) no-repeat;
    background-size: cover;
  }
}

.part-des {
  width: 12.19rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.6rem;
}

.swiper-form-box {
  width: calc(12.19rem + 1.8rem);
  margin-left: auto;
  margin-right: auto;
}

.part-tg {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.part-tip {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.general-form {
  margin-left: auto;
  margin-right: auto;
  width: 12.19rem;
}

.slide-box {
  margin-left: auto;
  margin-right: auto;
  width: 14rem !important;
}

.tips-form {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 12.19rem;
  margin-top: 0.5rem;

  padding: 0.6rem 0.9rem 0.5rem;
  // border: 0.01rem solid #3b3b39;
  background: url(../assets/images/skill-bg.png) no-repeat;
  background-size: 100% 100%;

  .strength {
    display: flex;
    align-items: center;
    font-size: 0.24rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    .xue {
      width: 0.29rem;
      height: 0.31rem;
      cursor: pointer;
    }

    .flex {
      width: 4rem;
      justify-content: space-between;
    }

    .red {
      margin-left: 0.2rem;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 2px;
      color: #a5b5b8;
    }
  }

  .form-item {
    position: relative;
    display: flex;
    margin-bottom: 0.2rem;
    font-size: 0.24rem;
    font-weight: bold;

    &.middle {
      align-items: center;
    }

    .name {
      width: 1.6rem;
    }

    .cont {
      flex: 1;
    }

    .input-skill,
    .input-cont {
      width: 1.35rem;
      height: 0.5rem;
      background: rgba(26, 53, 59);
      border: 0;
      border-radius: 0.06rem;
      font-size: 0.18rem;
      text-indent: 0.2rem;
      color: #fff;
      font-family: "Microsoft Yahei";
    }

    .input-cont {
      width: 87%;
      height: 1.9rem;
      padding: 0.1rem 0;
      resize: none;
    }

    .add {
      width: 0.5rem;
      height: 0.5rem;
      line-height: 0.45rem;
      display: flex;
      justify-content: center;
      font-size: 0.3rem;
      background: rgba(26, 53, 59);
      border-radius: 0.06rem;
      cursor: pointer;
    }

    .com-num {
      position: absolute;
      right: 1.5rem;
      bottom: 0.2rem;
      color: #bbb;
      font-weight: normal;
    }
  }

  .input-design {
    width: 1.8rem;
    height: 0.5rem;
    background: rgba(26, 53, 59);
    border-radius: 0.06rem;
    border: 0;
    font-size: 0.18rem;
    text-indent: 0.2rem;
    color: #fff;
  }

  .think {
    position: relative;
    font-size: 0.24rem;
    color: #fff;

    .name {
      margin-bottom: 0.2rem;
      font-weight: bold;
    }

    .com-num {
      position: absolute;
      right: 0.15rem;
      bottom: 0.2rem;
      color: #bbb;
    }

    .xuantian {
      font-size: 0.16rem;
      color: #787878;
    }
  }

  .area {
    width: 100%;
    height: 1.5rem;
    padding: 0.1rem 0;
    background: rgba(26, 53, 59);
    border-radius: 0.06rem;
    border: 0;
    font-size: 0.18rem;
    text-indent: 0.2rem;
    color: #fff;
    font-family: "Microsoft Yahei";
    resize: none;
  }

  .submit-box {
    padding-bottom: 0.5rem;
    margin: 0.4rem auto 0.4rem;
    text-align: center;
    border-bottom: 0.01rem solid #2f2e2e;
  }

  .attention {
    line-height: 1.8;
    color: #fff;
  }
}

.swiper-background {
  position: absolute;
  left: 0;
  width: 100%;
  height: 5.65rem;
  background: url(../assets/images/select-bg.png) no-repeat;
  background-size: 100% auto;
}

.change-box {
  z-index: 9;
  display: flex;
  position: relative;
  left: 2.5rem;
  top: -1.4rem;
  width: 3.68rem;
  flex-direction: column;
  align-items: center;

  .avatars {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.1rem;

    .item {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      width: 0.8rem;
      height: 0.8rem;
      background: url(../assets/images/unselect.png);
      background-size: cover;

      img {
        width: 0.76rem;
        height: 0.76rem;
        border-radius: 50%;
      }
    }

    .active {
      background: url(../assets/images/selected.png);
      background-size: cover;
    }
  }

  .change-btn {
    width: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      width: 0.16rem;
      height: 0.26rem;
      cursor: pointer;
      background: url(../assets/images/kuang.png) no-repeat bottom;
      background-size: 0.16rem auto;

      &:nth-child(1) {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0.18rem;
          left: 0.22rem;
          height: 0.02rem;
          width: 0.68rem;
          background: #fff;
          opacity: 0.2;
        }
      }

      &:nth-child(2) {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0.18rem;
          left: 0.22rem;
          height: 0.02rem;
          width: 0.68rem;
          background: #fff;
          opacity: 0.2;
        }
      }

      &:nth-child(3) {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0.18rem;
          left: 0.22rem;
          height: 0.02rem;
          width: 0.68rem;
          background: #fff;
          opacity: 0.2;
        }
      }
    }

    .active {
      background: url(../assets/images/xuanzhong.png) no-repeat;
      background-size: cover;
    }
  }
}

.swiper-form .slide-box {
  .name {
    width: 6.25rem;
    margin-top: 0.5rem;
  }

  .des {
    width: 6.25rem;
    margin-bottom: 0.22rem;
    margin-left: 0.01rem;
  }

  .supplement {
    white-space: pre;
    width: 6.25rem;
    margin-left: 0.01rem;
    font-family: MicrosoftYaHei;
    font-size: 0.16rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.28rem;
    letter-spacing: 0px;
    color: #a5b5b8;
  }

  .man {
    position: absolute;
    // top: -4rem;
    width: 6.44rem;
    height: 5.61rem;
    right: 0;
    top: 0;

    .role-avatar {
      width: 6.44rem;
      height: 5.61rem;
    }
  }
}
</style>
