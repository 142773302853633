<template>
  <div class="copyright-box">
    <div class="copy-right">
      <div class="copyright-left">
        <a href="http://www.yokagames.com/" target="_blank" rel="nofollow"><img src="@/assets/images/footer/logo.png" alt="YOKAGAMES 游卡桌游" class="flogo"/></a>
        <span class="lines"></span>
        <a href="http://sq.ccm.gov.cn/ccnt/sczr/service/business/emark/toDetail/4028c08b5558c352015576c4a46a1eed" target="_blank" rel="nofollow"><img src="@/assets/images/footer/wen.png" alt="" class="wen"/></a>
        <a href="http://www.cyberpolice.cn/wfjb/" target="_blank" rel="nofollow"><img src="@/assets/images/footer/jing.png" alt="" class="jing"/></a>
        <a href="http://idinfo.zjamr.zj.gov.cn/bscx.do?method=lzxx&amp;id=3301083301080000062214" target="_blank"><img src="@/assets/images/footer/gong.png" alt="" class="gong"/></a>
        <a href="http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/gameNetTag/F35FC9BEF9D152AEE040007F01003007" target="_blank" rel="nofollow"><img src="@/assets/images/footer/youxidzbq.png" class="dz"/></a><span class="lines"></span>
      </div>
      <div class="copyright-text">
        抵制不良游戏<span>|</span>拒绝盗版游戏<span>|</span>注意自我保护<span>|</span>谨防受骗上当<span>|</span>适度游戏益脑<span>|</span>沉迷游戏伤身<span>|</span>合理安排时间<span>|</span>享受健康生活<br />
        网络文化经营许可证 浙网文[2016]0251-121号&nbsp;&nbsp;&nbsp;文网游备字[2010]C-CBG009号&nbsp;&nbsp;&nbsp;游戏批准文号 科技与数字【2010】284号<br />
        出版单位 杭州边锋网络技术有限公司 版号 新广出审【2016】775号 游戏备案号 文网游备字〔2016〕Ｍ-CSG2524号<br />
        增值电信业务经营许可证号码, <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">[浙B2-20160108]</a>&nbsp;&nbsp;&nbsp;浙公网安备&nbsp;33010502005081号&nbsp;&nbsp;&nbsp;杭州游卡网络技术有限公司　版权所有<br />
        杭州市良渚数字文化社区B座3楼,&nbsp;&nbsp;&nbsp;接待时间：周一至周五 9:30-17:30&nbsp;&nbsp;&nbsp;适龄提示：12岁以上使用 <a href="http://www.yokagames.com/jiazhang/" rel="nofollow">家长监督</a>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/copyright.less';

export default {
  name: 'Copyright',
};
</script>
