<template>
  <div v-show="visible">
    <div class="fades" @click="onClose"></div>
    <slot></slot>
  </div>
</template>
<script>
import '@/assets/css/pop.css';
export default {
  name: 'pop',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClose() {
      this.$emit('hidePop');
    },
  },
};
</script>
