//筛选角色
export const role_name = [
  {
    id: 0,
    name: '全部',
  },
  {
    id: 1,
    name: '谋郭嘉',
  },
  {
    id: 2,
    name: '马钧',
  },
  {
    id: 3,
    name: '荀彧荀攸',
  },
  {
    id: 4,
    name: '锦囊·望梅止渴',
  },
  // {
  //   id: 5,
  //   name: '小约翰可汗',
  // },
  // {
  //   id: 6,
  //   name: '朱铁雄',
  // },
  // {
  //   id: 7,
  //   name: '李白',
  // },
];

//角色介绍
export const roleInfo = [
  {
    id: 1,
    number: '①',
    name: '谋-郭嘉',
    des: '郭嘉（170年—207年），字奉孝，颍川阳翟（今河南禹州）人。中国东汉末年曹操的重要谋士。',
    supplement:'提示：由于谋郭嘉为谋系列双形态武将，在设计时请注意其技能组中需要包含一个可在游戏\n开始自选“阴”或“阳”状态的转换技，并描述其触发条件以及在“阴”和“阳”两种\n状态下分别对应的效果。\n例如：\n英谋：转换技，每回合限一次，当你对其他角色使用牌后，你可以选择其中一个目标，\n阳：你摸牌至与其相同（至多摸五张），然后视为对其使用一张【火攻】；\n阴：令全场手牌数最多的一名角色对其使用手中所有【杀】和伤害锦囊牌。（没有需弃牌\n至与你相同'
  },
  {
    id: 2,
    number: '④',
    name: '马钧',
    des: '字德衡，三国时期曹魏发明家。扶风（今陕西省兴平市）人，是中国古代科技史上最负盛名的机械发明家之一。发明了龙骨水车、发石机等，对科学发展和技术进步做出了贡献。',
  },
   {
    id: 3,
    number: '③',
    name: '荀彧荀攸',
    des: '荀彧字文若，荀攸字公达。二人系叔侄关系，均是汉末颍阴（今河南许昌市）人，曹操的重要谋士。在建计、密谋、匡弼、举人等方面多有建树。',
  },
  {
    id: 4,
    number: '②',
    name: '锦囊-望梅止渴',
    des: '出自《世说新语·假谲》。东汉末年，曹操率领部队去讨伐盘踞在宛城的张绣，烈日当空，将士们口渴难耐，曹操为了鼓舞士气，称“前方有茂盛的杨树林，到那里，各位可以尽情地吃梅子吃个够。”将士们一听说梅子及梅子的酸汁，就自然而然地想像起酸味，从而流出口水，顿时不觉得那么渴了。',
  },

  // {
  //   id: 5,
  //   number: '⑤',
  //   name: '小约翰可汗',
  //   des: 'bilibili 2022百大UP主、2022年度最高人气奖UP主、知名UP主，又名通辽可汗。他从正史之外，寻觅那些角落里身兼绝技的人物，将个体经历融入大时代背景中，用一期期“硬核狠人”，串起一段段自一战以来的冷门趣史；“奇葩小国”系列讲述着来自第三世界的群雄列传，将一本正经的书面记载，化解成小说般跌宕起伏的传奇。本次创玩节也将在游戏内给大家精彩内容。',
  // },
  // {
  //   id: 6,
  //   number: '⑥',
  //   name: '朱铁雄',
  //   des: '中国魔法少年的英雄梦，国风变装的热血与浪漫。抖音年度高光时刻作者，国风变装现象级人物，第六届“中国青年好网民”。',
  // },
  // {
  //   id: 7,
  //   number: '⑦',
  //   name: '李白',
  //   des: '李白，字太白，号青莲居士。唐朝伟大的浪漫主义诗人，爱好饮酒作诗，名列“酒中八仙”。李白所作词赋具有极高的开创意义及艺术成就，后世誉为“诗仙”。',
  // },
];
export const role_country =new Map([[1,'魏'],[2,'蜀'],[3,'吴'],[4,'群'],[5,'神']])
